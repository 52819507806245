<template>
  <div class="section mt-md-4" v-if="section">
    <div class="content-wrapper pa-3" v-if="section._id == '5e6a3736a89fc0152c4b81c8'">
      <!-- <a href="">
            <v-img max-height="700px" width="100%" contain src="/img/noted_analytics.png"> </v-img>
          </a> -->

      <!-- width="100%"
        height="800px" -->

      <!-- <iframe
        src="http://notedanalytics-21695961.hs-sites.com/en-us/noted-analytics-gap-selling-module"
        frameborder="0"
        style="overflow:hidden;height:90vh;width:100%"
        height="90vh"
        width="100%"
      >
      </iframe> -->

      <h1 class="mb-3 header-text">Resources</h1>

      <v-card class="pa-3">
        <v-row>
          <v-col cols="12" class="px-12 pb-12 text-center">
            <div class="mb-2"> Add Certification to LinkedIn Profile!</div>
            <v-btn class="mx-auto" color="primary" target="blank"
              href="https://www.linkedin.com/profile/add?startTask= Foundations%20of%20Gap%20Selling&name=Foundations%20of%20Gap%20Selling&organizationId=2356386">
              Add Certification
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="pa-3">
        <v-row>

          <v-col cols="4" class="px-12">
            <v-img class="mx-12" cover src="/img/notedanalytics.png"> </v-img>
          </v-col>

          <v-col cols="8">
            <v-img class="mb-2" fill height="55px" width="255px" src="/img/nalogo.png"> </v-img>
            Don’t fall back into old habits. Execute Gap Selling with the same Salesforce module
            used by Keenan and the ASG team.
            <div id="hs_cos_wrapper_dnd__banner-module-4"
              class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style=""
              data-hs-cos-general-type="widget" data-hs-cos-type="module">
              <div class="text-center">
                <v-btn class="mt-3" color="primary" dark outlined
                  href="http://notedanalytics-21695961.hs-sites.com/en-us/noted-analytics-gap-selling-module"
                  target="_blank">
                  Learn More
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div v-if="section._id != '5e6a3736a89fc0152c4b81c8'">
      <div class="pb-2 text-center show-all-notes d-none d-md-block">
        <div class="text-center mt-2">
          <v-btn text color="primary" @click="showNotesDialog = true">Show All Notes</v-btn>
        </div>
      </div>

      <v-dialog v-model="showNotesDialog" max-width="700">
        <v-card class="pa-1 text-center">
          <v-row>
            <v-col cols="12">
              <div class="text-right">
                <v-btn text @click="showNotesDialog = false" color="primary">EXIT</v-btn>
              </div>
            </v-col>
          </v-row>

          <h2 class="text-center mb-5">My Notes</h2>

          <NotesList v-bind:showDialog.sync="showNotesDialog"></NotesList>
        </v-card>
      </v-dialog>

      <v-dialog class="next-lesson-dialog" v-model="showNextLessonDialog" max-width="700" persistent>
        <v-card class="pa-5 text-center">
          <div class="nextLessonDialogWrapper justify-center d-flex align-center">
            <div>
              <h1 class="mb-5">Congratulations!</h1>

              <v-btn color="primary" @click="gotoNextLesson">
                Begin
                {{ nextLesson && nextLesson.section ? nextLesson.section.name : "Next Section" }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>

      <!-- <VideoCensored v-bind:showDialog.sync="showVideoCensoredDialog"></VideoCensored>

      <Bandwidth v-bind:showDialog.sync="showBandwidthDialog"></Bandwidth> -->

      <v-row v-if="lesson">
        <v-col class="my-0 py-0" cols="12" md="3" order="last" order-md="first">
          <SubMenu :section="section" :lessons="lessons" :activeLesson="lesson" :activeTrack="activeTrack"
            v-bind:activeLessonId.sync="lessonId"></SubMenu>
        </v-col>

        <v-col cols="12" md="9" order="first" order-md="last">
          <div class="content-wrapper" v-if="section._id != '5e6a3736a89fc0152c4b81c8'">
            <div v-if="lesson.type === 'lecture' && !dialogDemoError">
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column font-weight-bold primary--text lesson-name">
                  {{ lesson.parentLesson ? lesson.parentLesson.name : lesson.name }}
                </div>
              </div>

              <div v-if="lesson.description">
                <div class="lesson-description mx-12 mb-5" v-html="lesson.description"></div>
              </div>

              <div id="video-wrapper">
                <Lecture :bandwidth="bandwidth" :lesson="lesson" :section="section" :playVideo="playVideo"
                  @ended="getSection" v-bind:showDialog.sync="showExerciseDialog"
                  v-bind:exercisePart.sync="exercisePart"></Lecture>
              </div>
            </div>

            <div class="exercise-wrapper"
              v-if="lesson.type === 'exercise' && !lesson.parentLessonId && !dialogDemoError">
              <Exercise @submit="submitExercise" class="exerciseDialog" @ended="getSection" :exercise="lesson"
                v-bind:activeTrack.sync="activeTrack"></Exercise>
            </div>
          </div>

          <!-- <div class="text-center">Your video is {{ videoCensored ? "" : "not " }} censored</div> -->

          <!-- <v-row justify="end">
          <v-col cols="12" md="4">
            <div class="text-center">
              <div class="text-center">
                Video Quality:
                <strong>{{bandwidth}}</strong>
              </div>
              <v-btn link @click="showBandwidthDialog = true" color="primary">change</v-btn>
            </div>
          </v-col>
        </v-row>-->

          <div class="notes-wrapper mt-5" v-if="lesson.type === 'lecture' && section._id != '5e6a3736a89fc0152c4b81c8'">
            <div class="pl-1 py-2">Your Notes</div>

            <Notes class="text-editor" :lesson="lesson"></Notes>

            <div class="pb-2 text-center">
              <div class="text-center mt-2">
                <v-btn text color="primary" @click="showNotesDialog = true">Show All Notes</v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="showEndtrainingDialog" persistent fullscreen>
        <div class="end-training-wrapper text-center">
          <h1 class="pt-12">Congratulations!</h1>

          <h3 class="mt-5">You're on your way to becoming a Gap Seller! You got this!</h3>

          <div class="mt-12">
            <!-- <div>
            <v-btn
              href="http://surveygizmo.com/"
              target="_blank"
              text
              color="primary"
            >
              Take the Survey
            </v-btn>
          </div> -->

            <div class="mt-5">
              <v-btn href="/course-list" color="primary"> Back To Course List </v-btn>
            </div>
          </div>
        </div>
      </v-dialog>

      <v-dialog v-model="showExerciseDialog" max-width="1600" persistent>
        <Exercise class="exerciseDialog" v-bind:showDialog.sync="showExerciseDialog" isDialog="true"
          @submit="submitExercise" :exercise="lesson" :part="exercisePart" v-bind:activeTrack.sync="activeTrack">
        </Exercise>
      </v-dialog>

      <v-dialog v-model="dialogDemoError" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark href="/course-list">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-toolbar-title>Back To Demo</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <div class="row pt-12">
            <div class="col-12 text-center">
              <div class="demo-end">
                <h3 class="mb-2">Uh oh! That’s the end of your free training.</h3>

                <div>That sucks, just when you were getting into it.</div>

                <div>But it doesn’t have to be over.</div>
              </div>

              <!-- <v-btn
              class="my-3"
              href="https://gapsellingonlinetraining.com/"
              dark
              color="primary"
            >
              Click here to continue
            </v-btn> -->

              <p>Things only get better from here.</p>

              <div class="row pt-12">
                <v-col cols="12" class="text-center">
                  <v-btn href="/settings?tab=3"> Select your plan</v-btn>
                </v-col>
              </div>

              <p class="mt-8">
                Not ready yet,
                <a href="https://www.asalesguy.com/gap-selling/?ref=gapsellingonlinetraining_demo" target="_blank">
                  check out Gap Selling the book
                </a>
                and find out why it’s quickly become one of the best selling sales books in the
                world.
              </p>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-text {
  color: var(--v-primary-base);
}

.section,
.content-wrapper {
  min-height: 0;
}

.show-all-notes {
  position: absolute;
  top: 30px;
  max-width: 250px;
  right: 0;
  left: 0;
  margin: auto;
}

.plan-wrapper {
  margin: auto;
  max-width: 380px;
}

.demo-end {
  max-width: 600px;
  margin: auto;
}

.text-editor {
  background-color: white;
}

.save-note {
  text-decoration: underline;
}

#video-wrapper {
  margin: auto;
  padding: 0;
  // max-width: 900px;
}

video {
  width: 100% !important;
  height: auto !important;
}

// .exerciseDialog {
//   width: 50%;
//   height: 50vh;
//    margin: auto;
// }
// .exerciseDialog {
//   width: 100%;
//   height: 100vh;
// }
.content-wrapper {
  margin: 0;
  padding: 0;
  border: none;

  .lesson-name {
    padding: 25px 50px 25px 50px;
  }
}

.exercise-wrapper {
  margin: 0;
  padding: 0;
  border: none;
  background-color: #f2f2f2;
}

.notes-wrapper {
  margin: 10px 10px;
  padding: 0;
  border: none;
  background-color: #f2f2f2;
}

.next-lesson-dialog {
  z-index: 998;
}

.end-training-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}

.plan-header {
  width: 300px;
  color: #cb4f53;
}

// .plan-card {
//   cursor: pointer;
//   transition: 0.3s;
//   width: 100%;
//   .top {
//     background-color: #bebebe;
//     border-radius: 25px 25px 0 0;
//     color: white;
//     padding: 10px 20px;
//     height: 260px;
//     position: relative;

//     .pill {
//       color: #9eacaf;
//       background-color: white;
//       padding: 10px 0;
//       position: absolute;
//       top: 215px;
//       left: 0px;
//       right: 0px;
//       text-align: center;
//       font-size: 16px;
//       font-weight: bold;
//       .buy-now {
//         width: 80%;
//         padding-left: 20px;
//         padding-right: 20px;
//       }
//     }
//     .header-title {
//       font-size: 22px;
//       text-align: center;
//       text-transform: capitalize;
//     }
//     .money-sign {
//       font-size: 18px;
//       padding-top: 10px;
//     }
//     .amount.strike {
//       font-size: 25px;
//       padding-top: 10px;
//       padding-right: 8px;
//     }

//     .amount {
//       font-size: 35px;
//     }
//     .per {
//       font-size: 22px;
//       padding-top: 12px;
//     }
//   }

//   .bottom {
//     padding: 25px;
//     min-height: 185px;
//   }

//   // &.standard {
//   //   .top {
//   //     background-color: #9eacaf;
//   //     .pill {
//   //       color: #9eacaf;
//   //     }
//   //   }
//   // }
//   &.active {
//     .top {
//       background-color: var(--v-primary-base);
//       .pill {
//         color: var(--v-primary-base);
//       }
//     }
//   }

//   &:hover,
//   &.active {
//     box-shadow: 0 20px 18px 0 rgba(0, 0, 0, 0.2);
//   }
// }

@media (min-width: 460px) {
  .content-wrapper {
    min-height: 90vh;
    max-width: 90%;
    margin: auto;
    border: 1px solid #e27f6b;
  }

  //   margin: 50px 80px 0px 50px;
  // padding: 25px 25px 25px 25px;
  #video-wrapper {
    // margin: 0px 50px 0px 50px;
    padding: 0px 50px 25px 50px;
  }

  .notes-wrapper {
    max-width: 90%;
    margin: auto;
    padding: 10px 25px 25px 25px;
    border: 1px solid #e27f6b;
  }
}

.nextLessonDialogWrapper {
  height: 250px;
}
</style>

<script>
import { mapState } from "vuex";
// import analytics from '../module/analytics';
// import VideoCensored from "@/components/VideoCensored";
// import Bandwidth from "@/components/Bandwidth";
import SubMenu from "@/components/SubMenu";
import Exercise from "@/components/Exercise";
import Lecture from "@/components/Lecture";
import GET_SECTION from "../graphql/GetSection.gql";
import GET_LESSON from "../graphql/GetLesson.gql";

import Notes from "@/components/Notes";
import NotesList from "@/components/NotesList";
import router from "../router";

export default {
  components: {
    // VideoCensored,
    // Bandwidth,
    Exercise,
    Lecture,
    SubMenu,
    Notes,
    NotesList,
  },
  data() {
    return {
      lesson: null,
      nextLesson: null,
      showNextLessonDialog: false,
      lessons: null,
      section: null,
      exercisePart: null,
      videoSrc: null,
      playVideo: false,
      showVideoCensoredDialog: false,
      showBandwidthDialog: false,
      showExerciseDialog: false,
      showNotesDialog: false,
      exerciseId: "5df590a0cc0c644a2c63897a",
      lessonId: null,
      sectionId: null,
      getSectionError: null,
      activeTrack: 1,
      dialogDemoError: false,
      showEndtrainingDialog: false,
    };
  },
  computed: {
    videoCensored() {
      return this.$store.state.auth.videoCensored;
    },
    bandwidth() {
      return this.$store.state.auth.bandwidth || "low";
    },
    ...mapState(["auth"]),
  },
  mounted() {
    if (
      this.auth &&
      this.auth.authUser &&
      !this.auth.authUser.isTrainingUser &&
      !this.auth.authUser.isTrainingUserGapPlus &&
      this.auth.authUser.account.paymentPlan &&
      this.auth.authUser.account.paymentPlan.isTeam
    ) {
      router.push("/settings");
    }
    this.sectionId = this.$route.params.sectionId;
    this.getSection(null, true);
    // mainVideo.addEventListener('timeupdate', (event) => {
    //   console.log('The currentTime attribute has been updated. Again.', event);
    // });

    // mainVideo.addEventListener('change', () => {
    // });
  },
  watch: {
    // "$route.params.sectionId": function(oldVal, newVal) {
    //   this.sectionId = newVal;
    //   this.getSection();
    // },
    lessonId() {
      this.getLesson(this.lessonId);
    },
    videoCensored(newVal) {
      console.log("auth->videoCensored+++++++===+++++=====++", newVal);
      // this.videoCensored = newVal;
    },
    bandwidth(newVal) {
      console.log("auth->bandwidth+++++++===+++++=====++", newVal);
      // this.videoCensored = newVal;
    },
  },
  methods: {
    submitExercise(event) {
      const self = this;
      console.log("submitExercise->event", event);
      const question = event.exercise.questions[0];
      const option = question.options
        ? question.options.find((x) => x.value == question.answers[0])
        : null;

      if (event.loadNextLesson) {
        if (event.exercise._id == "5f972475a8e7a222473de0e6") {
          //  show ending popup
          this.showEndtrainingDialog = true;
          return;
        }
        this.getLesson(
          event.nextLessonId
            ? event.nextLessonId
            : option && option.nextLessonId
              ? option.nextLessonId
              : event.exercise.nextLessonId
        );
      } else {
        this.playVideo = false;

        setTimeout(() => {
          self.playVideo = true;
        }, 1);
      }
    },
    loadExercise(part) {
      // this.exercisePart = part;
      this.showExerciseDialog = true;
    },
    async getSection(nextLessonId, loadNextLesson) {
      // if (
      //   !this.auth ||
      //   !this.auth.authUser ||
      //   !this.auth.authUser.account ||
      //   ((!this.auth.authUser.account.payment ||
      //     (this.auth.authUser.account.payment && !this.auth.authUser.account.payment.id)) &&
      //     this.auth.authUser.account.paymentPlanId != "demo" &&
      //     this.auth.authUser.account.selectedPlanId != "po_internal")
      // ) {
      //   confirm("You are not auhorized to view this page!");
      //   router.push("/sign-in");
      //   return;
      // }
      const getSectionResult = await this.$apollo
        .query({
          query: GET_SECTION,
          fetchPolicy: "no-cache",
          variables: {
            _id: this.sectionId,
          },
        })
        .catch((error) => {
          this.getSectionError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      // console.log("getSectionResult", getSectionResult.data.section);
      this.section = getSectionResult.data.section[0];
      this.lessons = getSectionResult.data.section[0].lessons;
      if (loadNextLesson) {
        const inProgressLesson =
          this.auth.authUser.account.paymentPlanId != "demo"
            ? this.section.lessons.find((x) => x.inProgress)
            : this.section.lessons[0];
        this.exercisePart = null;

        if (nextLessonId) {
          this.getLesson(nextLessonId);
        } else if (inProgressLesson) {
          this.getLesson(this.lessonId ? this.lessonId : inProgressLesson._id);
        } else {
          this.getLesson(
            this.lessonId
              ? this.lessonId
              : this.$route.query.lessonid
                ? this.$route.query.lessonid
                : this.section.lessons[0]._id
          );
        }
      } else if (this.$route.query.lessonid) {
        this.lessonId = this.$route.query.lessonid;
      }
    },
    gotoNextLesson() {
      window.location.href = `/section/${this.nextLesson.section._id}`;
      this.showNextLessonDialog = false;
    },
    async getLesson(lessonId) {
      this.dialogDemoError = false;
      const getLessonResult = await this.$apollo
        .query({
          query: GET_LESSON,
          fetchPolicy: "no-cache",
          variables: {
            _id: lessonId,
          },
        })
        .catch((error) => {
          // check if demo
          console.log("error", error);
          if (error.message.indexOf("demo-user-error") > -1) {
            // not authed to view  this lesson with a demo account
            this.dialogDemoError = true;
            return;
          }
          if (error.message.indexOf("Not Active Account") > -1) {
            // not authed to view  this lesson with a demo account
            alert("This account is not active.");
            this.$router.push("/sign-in");
            return;
          }
          // check if demo

          this.getLessonError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      if (!getLessonResult) {
        return;
      }
      const lesson = getLessonResult.data.lesson[0];
      this.exercisePart = null;
      const thisLessonIndex = this.lessons.findIndex((l) => l._id == lessonId);

      if (lesson && lesson.section._id != this.sectionId) {
        // this.sectionId = lesson.section._id;
        // router.push({
        //   name: "section",
        //   params: { sectionId: lesson.section._id }
        // });

        this.nextLesson = lesson;
        this.showNextLessonDialog = true;

        // window.location.href = `/section/${lesson.section._id}`;
      } else {
        // console.log("getLessonResult", getLessonResult.data.lesson);
        if (!this.lesson || (this.lesson && this.lesson._id != lesson._id)) {
          this.lesson = lesson;
          this.lessonId = this.lesson._id;
        }
        if (this.lesson.type === "exercise" && this.lesson.parentLessonId) {
          this.showExerciseDialog = true;
        }
        setTimeout(function () {
          window.scrollTo(0, 0);
        }, 0);
      }
    },
    toCurrency(currencyString) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(currencyString);
    },
  },
};
</script>
